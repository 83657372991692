import { EnvironmentDef } from "./environment-def";

export const environment: EnvironmentDef = {
  production: true,
  backendUrl: "https://whitelee.api.stg.hyai.app",
  domain: "dev-5otlwylsz3ub3hdq.us.auth0.com",
  clientId: "qhu4oSdylAKWEWzfdPXQinTtg3UEDhCt",
  audience: "https://locahost:8000",
};

